// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("/Users/jonas/jpavlicek.de/Kunden/165_Optique/_Repos/web-optique/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-impressum-jsx": () => import("/Users/jonas/jpavlicek.de/Kunden/165_Optique/_Repos/web-optique/src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-index-jsx": () => import("/Users/jonas/jpavlicek.de/Kunden/165_Optique/_Repos/web-optique/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-leistungen-jsx": () => import("/Users/jonas/jpavlicek.de/Kunden/165_Optique/_Repos/web-optique/src/pages/leistungen.jsx" /* webpackChunkName: "component---src-pages-leistungen-jsx" */),
  "component---src-pages-marken-jsx": () => import("/Users/jonas/jpavlicek.de/Kunden/165_Optique/_Repos/web-optique/src/pages/marken.jsx" /* webpackChunkName: "component---src-pages-marken-jsx" */),
  "component---src-pages-team-jsx": () => import("/Users/jonas/jpavlicek.de/Kunden/165_Optique/_Repos/web-optique/src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */),
  "component---src-pages-termin-jsx": () => import("/Users/jonas/jpavlicek.de/Kunden/165_Optique/_Repos/web-optique/src/pages/termin.jsx" /* webpackChunkName: "component---src-pages-termin-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/jonas/jpavlicek.de/Kunden/165_Optique/_Repos/web-optique/.cache/data.json")

